import React from 'react'

const ProductTitle = ({image}) => {
  return (
    <div style={{marginTop:"40px"}} >
        <img src={image} alt="" />
    </div>
  )
}

export default ProductTitle